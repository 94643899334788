import React, { useState, useLayoutEffect } from "react"
import { Link, graphql } from "gatsby"
import Header from "../components/headers/blogHeader"
import GetTags from "../components/GetTags"
import Footer from "../components/footers/footer"
import Post from "../components/Post"
import SEO from "../components/seo"
import DropdownIcon from "@material-ui/icons/ExpandMore"
import NextIcon from "@material-ui/icons/ArrowForward"
import PrevIcon from "@material-ui/icons/ArrowBack"

const BlogTag = ({ data, pageContext }) => {
  const [menuOpen, setOpen] = useState(false)

  const tag = pageContext.tag
    .toString()
    .toLowerCase()
    .replace(/-+/g, " ")
    .split(",")[0]
  return (
    <>
      <Header />
      <SEO
        title={
          "'" +
          tag.charAt(0).toUpperCase() +
          tag.slice(1) +
          "'" +
          " blog topics"
        }
        description={
          "View all blog topics for " +
          "'" +
          tag.charAt(0).toUpperCase() +
          tag.slice(1) +
          "'"
        }
      />
      <main style={{ marginTop: "4.4em" }}>
        <div id="post-options-container">
          <div id="latest-posts">Latest posts in </div>
          <div id="tag-menu">
            {menuOpen ? (
              <div
                id="menu-opened"
                onClick={() => setOpen(menuOpen => !menuOpen)}
              >
                {tag}
                <DropdownIcon style={{ marginLeft: "0.3em" }} />
              </div>
            ) : (
              <div
                id="menu-closed"
                onClick={() => setOpen(menuOpen => !menuOpen)}
              >
                {tag}
                <DropdownIcon style={{ marginLeft: "0.3em" }} />
              </div>
            )}

            {menuOpen ? (
              <div id="dropdown-container">
                <div id="dropdown">
                  <Link to="/blog/" id="tag">
                    All Topics
                  </Link>
                  <GetTags />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div id="posts-grid">
          {" "}
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Post
              title={node.frontmatter.title}
              author={node.frontmatter.author}
              path={node.frontmatter.title
                .toString()
                .toLowerCase()
                .replace(/\s+/g, "-")
                .replace(/[^\w-]+/g, "")
                .replace(/--+/g, "-")}
              date={node.frontmatter.date}
              body={node.excerpt}
              readTime={node.frontmatter.readTime}
              fluid={node.frontmatter.image.childImageSharp.fluid}
              tags={node.frontmatter.tags}
              key={node.id}
            />
          ))}
        </div>
        <div id="pagination">
          <div id="pagination-button-container">
            {pageContext.previousPagePath ? (
              <Link to={pageContext.previousPagePath} id="page-button">
                <PrevIcon
                  style={{ marginRight: "-2px", marginBottom: "-2px" }}
                />{" "}
                Prev
              </Link>
            ) : null}

            {pageContext.nextPagePath ? (
              <Link to={pageContext.nextPagePath} id="page-button">
                Next{" "}
                <NextIcon
                  style={{ marginLeft: "-2px", marginBottom: "-2px" }}
                />
              </Link>
            ) : null}
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export const TagQuery = graphql`
  query($skip: Int!, $limit: Int!, $tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
      filter: { frontmatter: { type: { eq: "blog" }, tags: { eq: $tag } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            author
            tags
            date(formatString: "MMM Do YYYY")
            path
            title
            description
            type
            readTime
            image {
              childImageSharp {
                fluid(maxHeight: 350, maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`

export default BlogTag
